import React from 'react';
import { useSelector } from 'react-redux';

import Panel from '~/components/Panel';
import Product from '~/modules/promotional/components/Product';

const ProductsListView = () => {
  const { selectedProducts } = useSelector(state => state.promotional);

  return (
    <Panel
      title="Produtos"
      description="Produtos que estão participando da promoção"
    >
      <div>
        {selectedProducts?.map(item => (
          <Product key={item.id || item} data={item} isSelected />
        ))}
      </div>
    </Panel>
  );
};

export default ProductsListView;
